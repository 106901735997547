import React from 'react';
import { graphql } from 'gatsby';
import { theme } from '../styles';
import { SanityStateSpecificTerms, SanityTerms } from '../../graphql-types';
import TermsBlock from '../components/TermsPage/TermsBlock';
import { SEO } from '../components/SEO';
import styled from '@emotion/styled';
import { ThemeProvider } from '@parsleyhealth/cilantro-ui';

interface StateSpecificTermsModalProps {
  data: {
    sanityStateSpecificTerms: SanityStateSpecificTerms;
    sanityTerms: SanityTerms;
  };
}

export default function StateSpecificTermsModal({
  data
}: StateSpecificTermsModalProps) {
  const { sanityStateSpecificTerms, sanityTerms } = data;
  const pageTitle =
    sanityStateSpecificTerms && sanityStateSpecificTerms.title
      ? sanityStateSpecificTerms.title
      : 'Terms & Conditions';
  const handleClick = () => {
    window.close();
  };
  return (
    <ThemeProvider>
      <div style={{ position: `relative` }}>
        <CloseButton onClick={() => handleClick()}>X</CloseButton>
        <SEO pageTitle={pageTitle} noindex={true} />
        <TermsBlock firstInPage={true} {...sanityTerms} />
        <TermsBlock firstInPage={false} {...sanityStateSpecificTerms} />
      </div>
    </ThemeProvider>
  );
}

const CloseButton = styled.button`
  background: transparent;
  display: block;
  position: fixed;
  top: ${theme.space[2]}px;
  right: ${theme.space[2]}px;
  border: none;
  color: ${theme.colors.greyMedium};
  font-size: 1.5em;
  cursor: pointer;
  font-weight: 200;

  @media screen and (min-width: ${theme.breakpoints[2]}) {
    top: ${theme.space[5]}px;
    right: ${theme.space[5]}px;
  }
`;

export const query = graphql`
  query StateSpecificTermsPageModalTemplateQuery($id: String!) {
    sanityStateSpecificTerms(id: { eq: $id }) {
      id
      title
      revisedAt(formatString: "MMMM DD, YYYY")
      _rawBody
      published
    }
    sanityTerms(slug: { current: { eq: "clinical-membership-terms" } }) {
      id
      title
      revisedAt(formatString: "MMMM DD, YYYY")
      _rawBody
    }
  }
`;
